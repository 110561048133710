import React from "react";
import Layout from "../components/layout/Layout";
import Seo from "../components/seo";
import backgroundImage from "../images/Image1copy.jpg";

// import bgImg from "../images/AboutUs.jpg";

const about = () => {
  return (
    <Layout>
      <Seo title="About Us" />
      <section>
        <div
          className="flex flex-col justify-items-center justify-center w-full"
          style={{ position: `relative` }}
        >
          <div
            className="w-screen bg-cover"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundPosition: `bottom`,
              backgroundRepeat: `no-repeat`,
              minWidth: `100%`,
              backgroundSize: `cover`,
              minHeight: `300px`,
              opacity: `75%`,
            }}
          ></div>
          <div
            className="w-full justify-items-center align-items-center text-center"
            style={{ position: `absolute`, width: `100vw` }}
          >
            <h1 className="text-4xl lg:text-5xl text-white font-bold text-shadow">
              About Us
            </h1>
          </div>
        </div>
      </section>
      
      <section id="about">
        <div className="flex flex-col justify-center items-center bg-white p-8 md:p-16  text-justify ">
          <div className="xl:w-1/2 w-full">
          <p className="text-gray-800 font-light lg:text-lg p-2 lg:p-6 text-center lg:text-center">
          Wilson & Farr Cleaning Solutions was established in 2020 by
          proprietors Alex and Tamara. Using their managerial skills,
          backgrounds in production facilities and healthcare they were able to
          make attention to detail an essential part of their day-to-day
          operations. Quality, integrity and professionalism were integral
          values that enabled them to thrive in their fields. 
          </p>
          <p className="text-gray-800 font-normal p-6 lg:text-lg lg:p-12 text-center lg:text-center">
          The goal when starting Wilson & Farr Cleaning Solutions was simple:<br />
          To exceed client’s expectations by providing outstanding customer
          service, and delivering meticulous quality-focused cleaning
          operations.
          </p>
          <p className="text-gray-900 font-light p-2 lg:text-lg lg:p-6 text-center lg:text-center">
          Helping clients celebrate milestones and assisting
          through life transitions, Wilson & Farr Cleaning Solutions helps to
          give back to their community. Whether preparing homes for listing,
          assisting older adults, alleviating stress in busy households, or
          creating a safe and healthy work environment, they are devoted to
          making spaces <em>sparkle</em>.
          </p>
        </div>
        </div>
      </section>
    </Layout>
  );
};

export default about;
